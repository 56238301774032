import React, { useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";

const ClientSetupItem = ({ item = null, details = null, setLists = () => {} }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const clientSetup = async () => {
    try {
      setIsDisabled(true);
      const { data } = await apiRequest("POST", `/clients/:client_id/setup`, { body: { code: item.code }, params: { client_id: details?._id } }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
      setLists((prevData) => prevData.map((item) => (item?._id === data?.data?._id ? { ...item, completed_steps: data?.data?.completed_steps } : item)));
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <div className="flex justify-between gap-4 py-4">
      <div className="flex-shrink flex-grow">
        <div className="flex items-center gap-4">
          <div className="text-lg font-semibold text-gray-600">{item.name}</div>
          <div className={classNames("rounded border px-2 py-1 text-[10px] font-semibold !leading-none", details?.completed_steps?.find((step) => step?.code === item?.code) ? "border-green-500 text-green-500" : "border-red-400 text-red-500")}>
            {details?.completed_steps?.find((step) => step?.code === item?.code) ? "Completed" : "Pending"}
          </div>
        </div>
        <div className="text-sm text-gray-400">{item.description}</div>
      </div>
      {!details?.completed_steps?.find((step) => step?.code === item?.code) && (
        <div className="w-30 flex flex-shrink-0 flex-col justify-between">
          <Button
            disabled={isDisabled}
            loading={isDisabled}
            version="primary"
            onClick={clientSetup}>
            Manually Launch
          </Button>
        </div>
      )}
    </div>
  );
};

export default ClientSetupItem;
