import { classNames } from "src/helpers/classNames";

const TRHeader = ({ children, theadClass, rowClass }) => {
  return (
    <thead className={classNames(theadClass)}>
      <tr className={classNames("bg-gray-50", rowClass)}>{children}</tr>
    </thead>
  );
};

export default TRHeader;
