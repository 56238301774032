import React, { useEffect } from "react";
import ClientSetupItem from "./ClientSetupItem";
import useFetch from "src/hooks/useFetch";
import NoData from "src/components/Shared/NoData/NoData";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";

const ClientSetup = ({ details = null, setLists = () => {} }) => {
  const {
    response: { data: steps },
    status: { done: isLoaded },
    refreshData,
  } = useFetch(`/registration/steps`, {
    query: {
      used_for: details?.existing_client ? ["existing_client", "both"] : ["new_client", "both"],
    },
  });

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?._id]);

  return (
    <div className="relative">
      {isLoaded ? (
        steps?.length > 0 ? (
          <div className="divide-y divide-gray-200">
            {steps.map((item) => (
              <ClientSetupItem
                key={item._id}
                item={item}
                details={details}
                setLists={setLists}
              />
            ))}
          </div>
        ) : (
          <NoData />
        )
      ) : (
        <Preloader color="text-gray-400 fill-gray-700" />
      )}
    </div>
  );
};

export default ClientSetup;
