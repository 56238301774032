import React from "react";
import EmailTemplateSettings from "./EmailTemplateSettings";
import SmtpSettings from "./SmtpSettings";

const SiteEmailManager = () => {
  return (
    <div className="grid gap-y-4">
      <EmailTemplateSettings />
      <SmtpSettings />
    </div>
  );
};

export default SiteEmailManager;
