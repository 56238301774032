import React from "react";
import { previousDateInString } from "src/helpers/dateHelper";

const ClientItems = ({ data = null }) => {
  return (
    <>
      <div className="max-w-[240px] items-start overflow-hidden">
        <div className="w-full truncate text-left text-base font-semibold text-gray-900">{data.company_name || data.name}</div>
        <div className="w-full truncate text-left text-sm text-gray-500">{data.domain}</div>
      </div>
      <div className="absolute right-1 top-2 flex gap-x-2">
        <time
          dateTime={data.created_at}
          className="flex h-6 items-center justify-center whitespace-nowrap rounded bg-gray-100 px-2 text-xs font-semibold text-gray-400 transition-all duration-200 group-hover:bg-white">
          {previousDateInString(data.created_at)}
        </time>
      </div>
    </>
  );
};

export default ClientItems;
