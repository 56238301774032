import { LOGGED_IN, PROFILE_UPDATE } from "src/actions/type";
import { apiRequest } from "src/async/apiUtils";
import { apiUrl } from "src/config/host";
import { asyncLocalStorage } from "src/helpers/asyncLocalStorage";

export const login = (body) => async (dispatch) => {
  try {
    const { data: resData } = await apiRequest("POST", `/login`, { body });

    if (resData.status === 200) {
      dispatch({ type: LOGGED_IN, payload: resData.data });
      localStorage.setItem("access_token", resData.access_token);
      localStorage.setItem("refresh_token", resData.refresh_token);
      return { message: resData.message };
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const changePasswordFirstTime = (body) => async (dispatch) => {
  try {
    const { data: resData } = await apiRequest("POST", `/change-password`, { body });

    if (resData.status === 200) {
      dispatch({ type: LOGGED_IN, payload: resData.data });
      localStorage.setItem("access_token", resData.access_token);
      localStorage.setItem("refresh_token", resData.refresh_token);
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateUser = (body) => (dispatch) => {
  dispatch({ type: PROFILE_UPDATE, payload: { name: body.name, email: body.email } });
};

export const getAuthorizedUser = () => async (dispatch) => {
  try {
    const { data: resData } = await apiRequest("GET", `/users/self-details`);
    if (resData.status === 200) {
      dispatch({ type: LOGGED_IN, payload: resData.data });
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    if (error.message !== "Failed to fetch" && error.message !== "The user aborted a request.") {
      throw new Error(error.message);
    }
  }
};

export const logout = async () => {
  await asyncLocalStorage.removeItem("access_token");
  await asyncLocalStorage.removeItem("refresh_token");
  window.location.href = "/";
};

export const refreshTokenVerify = async () => {
  const refreshTokenRes = await fetch(`${apiUrl}/refresh-token/verify`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
    }),
    body: JSON.stringify({}),
  });

  let refreshTokenData = {};

  if (refreshTokenRes.status === 200) {
    refreshTokenData = await refreshTokenRes.json();
    localStorage.setItem("access_token", refreshTokenData.access_token);
    localStorage.setItem("refresh_token", refreshTokenData.refresh_token);
  } else {
    logout();
  }

  return { refreshTokenRes, refreshTokenData };
};
