import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "src/actions/auth";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import AuthContainer from "src/components/Auth/AuthContainer";
import AuthHeader from "src/components/Auth/AuthHeader";
import AuthDataLayout from "src/layouts/AuthDataLayout";

const Login = ({ ...props }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const loginUser = async (e) => {
    try {
      if (isDisabled) {
        return;
      }

      e.preventDefault();
      setIsDisabled(true);
      const { message } = await props.login({
        email,
        password,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        token: localStorage.getItem("access_token"),
      });
      toast.success(message);
    } catch (error) {
      setIsDisabled(false);
      toast.error(error.message);
    }
  };

  return (
    <AuthDataLayout
      header={
        <AuthHeader
          title="Administrator login portal"
          subTitle={"Enter your email and password"}
        />
      }>
      <AuthContainer site={props.site}>
        <form
          className="flex w-full flex-col gap-y-2"
          autoComplete="on">
          {/* Email */}
          <div className="w-full">
            <Input
              inline={true}
              type="email"
              autoComplete="on"
              name="contact-email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  loginUser(e);
                }
              }}
            />
          </div>

          {/* Password */}
          <div className="w-full min-w-[200px]">
            <Input
              inline={true}
              autoComplete="on"
              type="password"
              name="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  loginUser(e);
                }
              }}
            />
          </div>
        </form>
        <div className=" flex w-full justify-end">
          <Link
            to="/forgot-password/submit-email"
            className="my-2 text-sm text-slate-800">
            Forgot Password?
          </Link>
        </div>
        <div className="mt-2">
          <Button
            className="h-9 !py-0 !font-normal "
            width="w-full"
            disabled={isDisabled}
            onClick={loginUser}>
            Login
          </Button>
        </div>
      </AuthContainer>
    </AuthDataLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { login })(Login);
