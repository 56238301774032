import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import AuthLayout from "src/layouts/AuthLayout";

const FreeAuthRoute = ({ user, site, children }) => {
  if (localStorage.getItem("access_token") || user?._id) {
    return <Navigate to="/admins" />;
  }

  return <AuthLayout site={site}>{children}</AuthLayout>;
};

const mapStateToProps = (state) => {
  return {
    user: state?.auth?.user,
    site: state?.site,
  };
};

export default connect(mapStateToProps, null)(FreeAuthRoute);
