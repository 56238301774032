import { Navigate, Route, Routes } from "react-router-dom";
import FreeAuthRoute from "src/routes/FreeAuthRoute";
import ProtectedRoute from "src/routes/ProtectedRoute";
import Login from "src/pages/auth/login";
import ForgotPassword from "src/pages/forgot-password/submit-email";
import ResetPassword from "src/pages/forgot-password/reset-password";
import WelcomePassword from "src/pages/forgot-password/welcome-password";
import Admins from "src/pages/admins";
import Activity from "src/pages/activity";
import SiteSettings from "src/pages/site-settings";
import RouteNotFound404 from "src/pages/route-not-found-404";
import TechnicalIssues from "src/pages/technical-issues";
import Clients from "src/pages/clients";

//Router
export default (
  <Routes>
    <Route
      path="/"
      element={
        <FreeAuthRoute>
          <Login />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/login"
      element={
        <FreeAuthRoute>
          <Login />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/forgot-password/submit-email"
      element={
        <FreeAuthRoute>
          <ForgotPassword />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/forgot-password/reset-password/:token"
      element={
        <FreeAuthRoute>
          <ResetPassword />
        </FreeAuthRoute>
      }
    />

    <Route
      path="/token/:token"
      element={
        <FreeAuthRoute>
          <WelcomePassword />
        </FreeAuthRoute>
      }
    />

    {/* ADMIN ROUTES */}
    <Route
      path="/admins"
      element={
        <ProtectedRoute>
          <Admins />
        </ProtectedRoute>
      }
    />
    {/* ADMIN ROUTES */}
    <Route
      path="/clients"
      element={
        <ProtectedRoute>
          <Clients />
        </ProtectedRoute>
      }
    />
    {/* ACTIVITY ROUTES */}
    <Route
      path="/activity"
      element={
        <ProtectedRoute>
          <Activity />
        </ProtectedRoute>
      }
    />

    {/* SETTINGS ROUTES */}
    <Route
      path="/site-settings"
      element={
        <ProtectedRoute>
          <SiteSettings />
        </ProtectedRoute>
      }
    />

    <Route
      path="/technical-issues"
      element={
        <FreeAuthRoute>
          <TechnicalIssues />
        </FreeAuthRoute>
      }
    />

    <Route
      path="/404"
      element={<RouteNotFound404 />}
    />
    <Route
      path="*"
      element={
        <Navigate
          replace
          to="/404"
        />
      }
    />
  </Routes>
);
