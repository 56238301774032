import "flatpickr/dist/themes/light.css";
import { useEffect, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import { classNames } from "src/helpers/classNames";
function DateSearch({ setStateDate, setEndDate, inline, placeholder })
{
  const [flatdate, setDate] = useState(null);

  const fp = useRef(null);
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  const enddate = new Date();
  enddate.setHours(23);
  enddate.setMinutes(59);

  useEffect(() =>
  {
    if (flatdate?.e && flatdate?.e[0] && flatdate?.e[1])
    {
      let first = flatdate?.e[0] ? new Date(flatdate?.e[0]).toISOString() : "";
      let last = flatdate?.e[1] ? new Date(flatdate?.e[1]).toISOString() : "";

      setStateDate(first);
      setEndDate(last);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatdate?.e[0] && flatdate?.e[1]]);

  return (
    <div className="hidden gap-x-1 md:flex">
      <div className="flex w-full sm:w-[290px] xl:w-[420px]">
        <label
          htmlFor="search"
          className="sr-only">
          Search
        </label>
        <div className={classNames(inline ? "w-full relative overflow-hidden rounded-md border border-gray-300 px-0 pt-2 focus-within:border-highlightColor md:pt-1 2xl:pt-2" : "relative flex w-full rounded-md shadow-sm")}>
          {inline && <div className="m-0 pl-3 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3 flex justify-between">{placeholder}</div>}
          <Flatpickr
            ref={fp}
            className={classNames(inline ? "w-full h-8 rounded-none border-none px-3 py-0 leading-none shadow-none md:h-7 md:px-2 2xl:h-8 2xl:px-3 focus:ring-0 text-gray-700 text-sm" : "h-[47px] md:h-[40px] 2xl:h-[47px] w-full rounded-md border-gray-300 text-sm focus:border-highlightColor focus:ring-0")}
            placeholder={placeholder}
            onChange={(e) =>
            {
              setDate({ e });
            }}
            options={{
              altInput: true,
              altFormat: "F j, Y",
              mode: "range",
              dateFormat: "d.m.y",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DateSearch;
