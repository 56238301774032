import moment from "moment";

export const formatTo12HourTime = (date) => {
  date = new Date(date);
  if (!(date instanceof Date)) {
    return "";
  }

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  const strTime = `${hours}:${formattedMinutes} ${ampm}`;
  return strTime;
};

export const formatDate = (date = null, format = "MM/DD/YYYY") => {
  if (!date) return "";
  return moment(new Date(date)).format(format);
};
