import { connect } from "react-redux";
import { ArrowTopRightOnSquareIcon, Cog8ToothIcon, DocumentDuplicateIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "src/helpers/classNames";
import Tabs from "src/components/Shared/Tabs/Tabs";
import CopyToClipboard from "react-copy-to-clipboard";
import Button from "src/components/Shared/Buttons/Button";
import { toast } from "react-toastify";
import ClientSetup from "./Setup/ClientSetup";

const ClientBodyContent = ({ data = null, setLists = () => {}, ...props }) => {
  const statuses = {
    pending: {
      text: "Pending",
      class: "border-gray-500 bg-gray-50 text-gray-500",
    },
    active: {
      text: "Active",
      class: "border-green-500 bg-green-50 text-green-500",
    },
    inactive: {
      text: "In-active",
      class: "border-red-500 bg-red-50 text-red-500",
    },
  };

  return (
    <div className="">
      <div className="main_item-background relative px-8 pb-6 pt-8">
        <div className="mb-4 flex items-center space-x-4">
          <div className={classNames("flex h-8 items-center justify-center gap-2 rounded border px-2", statuses[data?.status]?.class)}>{statuses[data?.status]?.text}</div>
        </div>
        <div className="mb-5 flex items-center space-x-4">
          <h1 className="text-4xl font-bold text-gray-900">{data?.company_name || data?.name}</h1>
        </div>
        <div className="flex items-center gap-5">
          <div className="flex w-40 items-center gap-2 font-semibold text-gray-600">
            <GlobeAltIcon className="h-5 w-5 stroke-2" /> Domain
          </div>
          <div className="relative flex w-full max-w-md items-center gap-5">
            <input
              type={"domain"}
              value={data?.domain || ""}
              className="h-10 w-full rounded border-0 bg-gray-200/40 px-3 py-0 pr-10 font-semibold !ring-0"
              onChange={() => {}}
            />
            <div className="absolute right-1 top-1 z-10 flex gap-x-1">
              <CopyToClipboard
                text={data?.domain}
                onCopy={() => toast.success("Copied")}>
                <Button
                  type="button"
                  version="default"
                  className="flex !h-8 !w-8 items-center justify-center rounded border border-gray-200 bg-white text-gray-500 shadow-sm">
                  <DocumentDuplicateIcon className="h-5 w-5 stroke-2" />
                </Button>
              </CopyToClipboard>
              <div className="flex !h-8 !w-8 items-center justify-center rounded border border-gray-200 bg-white text-gray-500 shadow-sm">
                <a
                  href={`https://${data?.domain}`}
                  rel="noreferrer"
                  target="_blank"
                  type="button"
                  version="default"
                  className="flex !h-8 !w-8 items-center justify-center rounded border border-gray-200 bg-white text-gray-500 shadow-sm">
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-2" />
                </a>
              </div>
            </div>
          </div>
          <div className="relative">
            <Button
              type="button"
              version="default"
              className="flex h-10 items-center justify-center px-2 py-0 font-semibold text-gray-500 underline">
              Edit
            </Button>
          </div>
        </div>
      </div>
      <div className="px-8 pb-4">
        <Tabs
          tabs={[
            {
              name: "Setup Steps",
              icon: Cog8ToothIcon,
              component: (
                <ClientSetup
                  details={data}
                  setLists={setLists}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(ClientBodyContent);
