import React from "react";
import { connect } from "react-redux";

const AuthHeader = ({ title, subTitle }) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{title}</h2>
      <div className="text-regular mt-2 text-center font-medium text-gray-600 ">{subTitle}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(AuthHeader);
