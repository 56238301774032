import ActivityList from "src/components/Activity/ActivityList";
import { H1 } from "src/components/Shared/Text/Headers";

function Activity() {
  return (
    <>
      <H1>Activity List</H1>
      <ActivityList />
    </>
  );
}

export default Activity;
