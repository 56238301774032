import { connect } from "react-redux";
import Section from "src/components/Shared/Containers/Section";
import EditContainer from "../Shared/Containers/EditContainer";
import { toast } from "react-toastify";
import { manageDomainSettings } from "src/actions/site";
import { useEffect, useState } from "react";
import Input from "../Shared/Forms/Inputs/Input";

const DomainSettings = ({ siteData, manageDomainSettings }) => {
  const [baseDomain, setBaseDomain] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setData = () => {
    setBaseDomain(siteData?.base_domain);
  };

  const handleOnSubmit = async () => {
    try {
      setIsSubmitting(true);
      const message = await manageDomainSettings({ base_domain: baseDomain });
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteData?.base_domain]);

  return (
    <>
      <div className="flex flex-col gap-y-4">
        <Section>
          <EditContainer
            title="Base Domain"
            subtitle="Add a base white-labeled domain"
            preview={{ text: baseDomain ? <>{baseDomain}</> : "No Domains" }}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onSuccess={handleOnSubmit}
            onCancel={setData}>
            <div className="min-w-[260px] flex-shrink">
              <div className="mb-4 space-y-4">
                <div className="w-96">
                  <Input
                    type="text"
                    autoComplete="off"
                    name="base-domain"
                    label="Base Domain"
                    inline={true}
                    value={baseDomain}
                    onChange={(e) => setBaseDomain(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </EditContainer>
        </Section>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, { manageDomainSettings })(DomainSettings);
