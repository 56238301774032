const NoData = ({ title = "No data found", type = "", image = { icon: null } }) => {
  return (
    <div className="bg-white rounded-md flex justify-center items-center gap-x-4 w-full h-[120px]">
      {image?.icon && <image.icon className="h-5 w-5" />}
      <p className="m-0 text-md font-medium text-gray-300 px-6 py-3">{type ? `No ${type} found` : title}</p>
    </div>
  );
};

export default NoData;
