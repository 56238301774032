import { connect } from "react-redux";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Search from "src/components/Shared/Search";
import MultiColumnLayout from "src/components/Shared/MultiColumnLayout/MultiColumnLayout";
import UserDateTime from "src/components/Users/List/UserDateTime";
import UserItems from "src/components/Users/List/UserItems";
import UserBodyContent from "src/components/Users/List/UserBodyContent";
import UserAddModal from "src/components/Users/Modal/UserAddModal";
import useGetListAndSelectItem from "src/hooks/useGetListAndSelectItem";
import { updateUser } from "src/actions/auth";

const UsersList = ({ filterUserType = ["admin"], updateUser, ...props }) => {
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const { lists, meta, isLoaded, limit, offset, setOffset, keyword, setKeyword, selectedItem, setSelectedItem, handleOnPaginationChange, refreshData } = useGetListAndSelectItem({
    url: `/users`,
    paginationData: { limit: 10, offset: 0 },
    additionalQuery: { type: String(filterUserType) },
  });

  const headerActions = [
    { _id: 2, name: "Activity" },
    { _id: 3, name: "Settings" },
    { _id: 4, name: "", type: "hr" },
    { _id: 5, name: "Delete" },
  ];

  return (
    <>
      <MultiColumnLayout
        title={"Admin List"}
        subTitle={`Total of ${meta?.count || 0} admins`}
        asideLoaded={isLoaded}
        aside={lists}
        paginationData={{ meta, limit, offset }}
        handleOnPaginationChange={handleOnPaginationChange}
        itemEntry={(item) => <UserItems data={item} />}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        mainHeaderContent={
          <>
            <div className="w-96">
              <Search
                keyword={keyword}
                setKeyword={(val) => {
                  setOffset(0);
                  setKeyword(val);
                }}
                placeholder={"Search"}
                inline={true}
              />
            </div>
            <div className="relative">
              <Button
                version="secondary"
                onClick={() => setIsManageModalOpen(true)}>
                <UserPlusIcon className="h-5 w-5" />
                Add admin
              </Button>
            </div>
          </>
        }
        bodyHeaderAdditionalContent={selectedItem?.last_login && <UserDateTime data={selectedItem} />}
        bodyHeaderActions={headerActions}
        bodyContent={
          <UserBodyContent
            data={selectedItem}
            onEdit={() => {
              setEditItem(selectedItem);
              setIsManageModalOpen(true);
            }}
          />
        }
      />
      {/* Add Modal */}
      <UserAddModal
        isOpen={isManageModalOpen}
        onCancel={() => {
          setIsManageModalOpen(false);
          setEditItem(null);
        }}
        data={editItem}
        onSuccess={() => {
          refreshData();
          setIsManageModalOpen(false);
          setEditItem(null);
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, { updateUser })(UsersList);
