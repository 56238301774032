import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { getAuthorizedUser, logout } from "src/actions/auth";
import AdminLayout from "src/layouts/AdminLayout";

const ProtectedRoute = ({ children, auth, getAuthorizedUser }) => {
  useEffect(() => {
    const loadUser = async () => {
      if (localStorage.getItem("access_token")) {
        try {
          await getAuthorizedUser();
        } catch (error) {
          if (error.message !== "CanceledError: canceled") logout();
        }
      } else {
        logout();
      }
    };

    loadUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?._id, getAuthorizedUser, logout, window.location.pathname]);

  if (!localStorage.getItem("access_token")) {
    return <Navigate to="/" />;
  }

  return <AdminLayout>{children}</AdminLayout>;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    site: state?.site,
  };
};

export default connect(mapStateToProps, { getAuthorizedUser })(ProtectedRoute);
