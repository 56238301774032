import { connect } from "react-redux";
import { PlusIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Search from "src/components/Shared/Search";
import MultiColumnLayout from "src/components/Shared/MultiColumnLayout/MultiColumnLayout";
import useGetListAndSelectItem from "src/hooks/useGetListAndSelectItem";
import PlanItems from "./PlanItems";
import PlanBodyContent from "./PlanBodyContent";
import PlanManageModal from "../Modal/PlanManageModal";

const PlanList = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const { lists, setLists, meta, isLoaded, limit, offset, setOffset, keyword, setKeyword, selectedItem, setSelectedItem, refreshData, handleOnPaginationChange } = useGetListAndSelectItem({
    url: `/plans`,
    paginationData: { limit: 10, offset: 0 },
  });

  const onOpenEditModal = () => {
    setEditItem(selectedItem);
    setIsAddModalOpen(true);
  };

  const headerActions = [{ name: "Edit", onClick: onOpenEditModal }, { name: "", type: "hr" }, { name: "Delete" }];

  return (
    <>
      <MultiColumnLayout
        title={"Plan List"}
        subTitle={`Total of ${meta?.count || 0} plans`}
        asideLoaded={isLoaded}
        aside={lists}
        paginationData={{ meta, limit, offset }}
        handleOnPaginationChange={handleOnPaginationChange}
        itemEntry={(item) => <PlanItems data={item} />}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        mainHeaderContent={
          <>
            <div className="w-96">
              <Search
                keyword={keyword}
                setKeyword={(val) => {
                  setOffset(0);
                  setKeyword(val);
                }}
                placeholder={"Search"}
                inline={true}
              />
            </div>
            <div className="relative">
              <Button
                version="secondary"
                onClick={() => {
                  setEditItem(null);
                  setIsAddModalOpen(true);
                }}>
                <PlusIcon className="h-5 w-5" />
                Add plan
              </Button>
            </div>
          </>
        }
        bodyHeaderActions={headerActions}
        bodyContent={
          <PlanBodyContent
            data={selectedItem}
            setLists={setLists}
          />
        }
      />
      {/* Plan Manage Modal */}
      <PlanManageModal
        title="Plan"
        secondaryTitle="Add"
        isOpen={isAddModalOpen}
        editItem={editItem}
        onSuccess={() => {
          setIsAddModalOpen(false);
          refreshData();
        }}
        onCancel={() => setIsAddModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(PlanList);
