import { Dialog, Transition } from "@headlessui/react";
import { ArrowPathIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import Button from "./Buttons/Button";

const Modal = ({ title, secondaryTitle = "", isOpen = false, size = "md", onCancel = () => {}, onSuccess = () => {}, onClose = null, defaultOptions = null, isLoading = false, defaultStyles = null, children, hideCrossIcon = false, closeModalOnClose = true, ...props }) => {
  const modalSize = {
    xs: "sm:max-w-lg",
    sm: "sm:max-w-xl",
    md: "sm:max-w-2xl",
    lg: "sm:max-w-3xl",
    xl: "sm:max-w-4xl",
    xxl: "sm:max-w-5xl",
    xxxl: "sm:max-w-6xl",
    xxxxl: "sm:max-w-7xl",
    xxxxxl: "sm:max-w-[1800px]",
    full: "max-w-full",
  };

  const [options, setOptions] = useState({
    onCancelButtonVisible: true,
    onCancelButtonText: "Cancel",
    onCancelButtonDisabled: false,
    onSuccessButtonVisible: true,
    onSuccessButtonText: "Submit",
    onSuccessLoaderVisible: false,
    onSuccessLoaderStart: false,
    onSuccessButtonDisabled: false,
  });

  const [styles, setStyles] = useState({
    containerStyles: "w-full",
    overFlowYVisible: true,
  });

  const [lastClicked, setLastClicked] = useState("success");

  useEffect(() => {
    if (defaultOptions) setOptions({ ...options, ...defaultOptions });
    if (defaultStyles) setStyles({ ...styles, ...defaultStyles });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions, defaultStyles]);

  useEffect(() => {
    if (isLoading) {
      setOptions((prev) => ({ ...prev, onSuccessLoaderStart: isLoading, onSuccessLoaderVisible: isLoading }));
    } else {
      setTimeout(() => {
        setOptions((prev) => ({ ...prev, onSuccessLoaderStart: isLoading, onSuccessLoaderVisible: isLoading }));
      }, 300);
    }
  }, [isLoading]);

  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={onCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex h-full max-h-full items-center justify-center p-2 text-center sm:px-6">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className={classNames(`relative h-auto max-h-screen transform rounded-lg bg-white p-0 text-left shadow-xl transition-all sm:my-8`, modalSize[size], styles.containerStyles)}>
                <div className="flex justify-between px-4 py-4 items-center">
                  <Dialog.Title
                    as="h3"
                    className="text-left text-xl font-semibold leading-6 text-gray-900">
                    {title} {secondaryTitle && <span className="text-gray-400">/ {secondaryTitle}</span>}
                  </Dialog.Title>
                  {!hideCrossIcon && (
                    <Button
                      type="button"
                      version="default"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-200 focus:outline-none !h-8"
                      onClick={() => {
                        onClose && closeModalOnClose ? onClose() : onCancel();
                      }}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </Button>
                  )}
                </div>

                <div className={`max-h-[calc(100vh-11rem)] px-4 sm:py-2 ${styles?.overFlowYVisible ? "" : "overflow-y-scroll"}`}>{children}</div>
                <div className="flex justify-end space-x-4 p-4">
                  {options.onCancelButtonVisible && (
                    <Button
                      disabled={(options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart) || options.onCancelButtonDisabled}
                      type="button"
                      version="default"
                      className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => {
                        setLastClicked("cancel");
                        onClose ? onClose() : onCancel();
                      }}>
                      {options.onCancelButtonText}
                      {options.onCancelLoaderVisible && options.onCancelLoaderStart && lastClicked !== "success" && <ArrowPathIcon className="ml-2 h-5 w-5 animate-spin" />}
                    </Button>
                  )}
                  {options.onSuccessButtonVisible && (
                    <Button
                      type="button"
                      version="default"
                      disabled={(options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart) || options.onSuccessButtonDisabled}
                      className={`inline-flex w-full justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none sm:w-auto sm:text-sm ${
                        !((options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart) || options.onSuccessButtonDisabled) ? "bg-highlightColor text-white border-transparent" : "bg-gray-200 text-gray-500 border-gray-300"
                      }`}
                      onClick={() => {
                        setLastClicked("success");
                        onSuccess();
                      }}>
                      {options.onSuccessButtonText}
                      {options.onSuccessLoaderVisible && options.onSuccessLoaderStart && (lastClicked === "success" || !options.onCancelLoaderVisible) && (
                        <ArrowPathIcon className={`ml-2 h-5 w-5 animate-spin text-white ${!((options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)) ? "text-white" : "text-gray-500"}`} />
                      )}
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
