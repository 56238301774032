import React from "react";
import { KeyIcon } from "@heroicons/react/24/outline";
import UserProfile from "src/components/Users/UserProfile";
import { previousDateInString } from "src/helpers/dateHelper";

const UserItems = ({ data = null }) => {
  return (
    <>
      <div className="h-10 w-10 overflow-hidden rounded-full">
        <UserProfile
          user={data}
          className="!bg-highlightColor !text-white"
        />
      </div>
      <div className="max-w-[240px] items-start overflow-hidden">
        <div className="w-full truncate text-left text-base font-semibold text-gray-900">{data.name}</div>
        <div className="w-full truncate text-left text-sm text-gray-500">{data.email}</div>
      </div>
      <div className="absolute right-2 top-3 flex gap-x-2">
        {data.type === "admin" && (
          <div className="flex h-6 w-6 items-center justify-center rounded bg-amber-100 text-sm font-semibold transition-all duration-200 group-hover:bg-white">
            <KeyIcon className="h-4 w-4 stroke-2 text-amber-500" />
          </div>
        )}
        <time
          dateTime={data.created_at}
          className="flex h-6 items-center justify-center whitespace-nowrap rounded bg-gray-100 px-2 text-xs font-semibold text-gray-400 transition-all duration-200 group-hover:bg-white">
          {previousDateInString(data.created_at)}
        </time>
      </div>
    </>
  );
};

export default UserItems;
