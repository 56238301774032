import { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";

const UserPasswordChange = ({ userDetails = null }) => {
  const jsonData = {
    old_password: "",
    password: "",
    confirm_password: "",
  };

  const [formData, setFormData] = useState(jsonData);
  const [isDisabled, setIsDisabled] = useState(false);

  const onClear = () => {
    setFormData(jsonData);
  };

  const onSubmit = async () => {
    try {
      setIsDisabled(true);
      const { data } = await apiRequest("PUT", "/users/:user_id/change-password", { body: formData, params: { user_id: userDetails?._id } });
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <div className="">
      <div className="flex w-full flex-wrap gap-y-4">
        <div className="w-full">
          <Input
            autocomplete="off"
            name="password"
            label="Old Password"
            inline={true}
            type="password"
            value={formData?.old_password}
            onChange={(e) => setFormData((prevData) => ({ ...prevData, old_password: e.target.value }))}
          />
        </div>
        <div className="w-full">
          <Input
            autocomplete="off"
            name="password"
            label="Password"
            inline={true}
            type="password"
            value={formData?.password}
            onChange={(e) => setFormData((prevData) => ({ ...prevData, password: e.target.value }))}
          />
        </div>
        <div className="w-full">
          <Input
            name="confirm_password"
            label="Confirm Password"
            inline={true}
            type="password"
            value={formData?.confirm_password}
            onChange={(e) => setFormData((prevData) => ({ ...prevData, confirm_password: e.target.value }))}
          />
        </div>
      </div>
      <div className="mt-5 flex w-full justify-end gap-x-3 pt-3">
        <Button
          version="gray"
          type="button"
          disabled={isDisabled}
          onClick={onClear}>
          Clear
        </Button>
        <Button
          type="button"
          onClick={onSubmit}
          loading={isDisabled}>
          Submit
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, {})(UserPasswordChange);
