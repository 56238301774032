import { GET_SITE_SETTINGS_DATA } from "src/actions/type";
import { objectToFormData } from "src/helpers";
import { apiRequest } from "src/async/apiUtils";

export const manageSiteSettings = (body) => async (dispatch) => {
  try {
    const { data } = await apiRequest(
      "PUT",
      `/site-settings`,
      {
        body: objectToFormData(body),
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const createStripeCredential = (body) => async (dispatch) => {
  try {
    const { data } = await apiRequest("POST", `/billing/stripe/settings`, {
      body,
    });

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteStripeCredential = () => async (dispatch) => {
  try {
    const { data } = await apiRequest("DELETE", `/billing/stripe/settings`);

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getOpenSiteSettings =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const { data } = await apiRequest("GET", `/site-settings/details`, { body }, { signal });

      if (data.status === 200) {
        dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getSiteSettings =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const { data } = await apiRequest("GET", `/site-settings`, { body }, { signal });

      if (data.status === 200) {
        dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const manageDomainSettings = (body) => async (dispatch) => {
  try {
    const { data } = await apiRequest("PUT", `/site-settings/domains`, { body });

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
