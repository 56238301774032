// prettier-ignore
import { connect } from "react-redux";
import TableRow from "src/components/Shared/Table/TableRow";
import { formatDate } from "src/helpers/formatDate";

const BasicActivityContent = ({ title = "", subTitles = [], noTitle = "" }) => {
  return title || subTitles ? (
    <div className="inline-flex flex-col text-xs text-gray-900">
      {title || ""}
      {subTitles &&
        subTitles?.length > 0 &&
        subTitles?.map(
          (text) =>
            text && (
              <div
                key={text}
                className="text-gray-400">
                {text}
              </div>
            ),
        )}
    </div>
  ) : (
    <div className="text-gray-400">*{noTitle || ""}*</div>
  );
};

function ActivityEntry({ me, activity, ...props }) {
  let { data, user_id } = activity;
  let { created_by_email, created_by_name, login_portal } = data;

  let primaryName,
    secondaryName = "";
  if (login_portal === "SYSTEM") {
    primaryName = "System Generated";
  } else if (created_by_name || created_by_email || user_id) {
    primaryName = created_by_name || user_id?.name || "N/A";
    secondaryName = created_by_email || user_id?.email || "No email listed";
  } else {
    if (activity?.public) {
      secondaryName = "*Publicly Accessed*";
    } else {
      secondaryName = "*User not found*";
    }
  }

  let title,
    subTitles,
    noTitle = "";
  let { type } = activity;

  let userTypes = ["USER_CREATE", "USER_UPDATE", "USER_PASSWORD_CHANGE", "USER_SEND_RESET_PASSWORD_EMAIL", "USER_SEND_WELCOME_EMAIL", "USER_STATUS_CHANGE_TO_ACTIVE", "USER_STATUS_CHANGE_TO_INACTIVE", "USER_DELETE"];

  if (userTypes.includes(type)) {
    // User Activity
    title = activity?.data?.user_email || activity?.data?.user_id?.email;

    let groupName = activity?.data?.group_name || activity?.data?.group_id?.name;
    let workspaceName = activity?.data?.workspace_name || activity?.data?.workspace_id?.name;
    let combinedName = groupName ? workspaceName + " > " + groupName : workspaceName;
    subTitles = [combinedName];
    noTitle = "User not found";
  } else if (type === "LOGIN") {
    title = "Authentication";
  } else if (type === "SETTINGS_UPDATE") {
    title = "Settings Update";
  } else if (type === "EMAIL_TEMPLATE_UPDATE") {
    title = activity?.data?.email_template_name || activity?.data?.email_template_id?.subject;
    noTitle = "Email template Deleted";
  }

  return (
    <TableRow keyId={activity?._id}>
      {/* COLUMN: Name */}
      <td className="w-1/3 whitespace-pre-wrap break-all px-3 py-2 pl-4 text-gray-500 sm:table-cell sm:pl-6 sm:text-xs md:w-1/5">
        <BasicActivityContent
          title={primaryName}
          subTitles={[secondaryName]}
        />
      </td>
      {/* COLUMN: Activity Type */}
      <td className="hidden w-1/5 whitespace-pre-wrap break-all px-3 py-2 text-gray-500 sm:text-xs lg:table-cell">
        <BasicActivityContent subTitles={[activity?.type]} />
      </td>
      {/* COLUMN: Description */}
      <td className="break-word hidden w-1/3 whitespace-pre-wrap px-3 py-2 text-gray-500 sm:table-cell sm:text-xs md:w-1/5">
        <BasicActivityContent
          title={title}
          subTitles={subTitles}
          noTitle={noTitle}
        />
      </td>
      {/* COLUMN: Date */}
      <td className="w-1/4 whitespace-pre-wrap break-all px-3 py-2 text-xs text-gray-500 sm:table-cell lg:w-1/5">
        <BasicActivityContent
          title={
            <>
              <div className="hidden text-gray-900 2xl:block">{formatDate(activity?.created_at, "LLLL")}</div>
              <div className="flex flex-col text-gray-900 2xl:hidden">
                {formatDate(activity?.created_at, "MM/DD/YYYY")} <span className="text-gray-400">{formatDate(activity?.created_at, "h:mm:ssA")}</span>
              </div>
            </>
          }
        />
      </td>
      {/* COLUMN: Location/IP Address */}
      <td className="hidden w-1/3 break-all px-3 py-2 text-xs text-gray-500 lg:table-cell">
        <BasicActivityContent
          title={login_portal}
          subTitles={[<>{me?.default_user && <>{data?.ip_address ? <span className="text-gray-500">{data?.ip_address}</span> : <span className="text-gray-300">{login_portal === "SYSTEM" ? "N/A" : "Not recorded"}</span>}</>} </>]}
        />
      </td>
    </TableRow>
  );
}

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, {})(ActivityEntry);
