import { LOGGED_IN, LOG_OUT, PROFILE_UPDATE } from "src/actions/type";

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGGED_IN:
      return { user: action.payload };
    case PROFILE_UPDATE:
      return { user: { ...state.user, ...action.payload } };
    case LOG_OUT:
      return { user: {} };
    default:
      return state;
  }
};

export default authReducer;
